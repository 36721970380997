import {
  EXECUTION_STATUS,
  FETCH_MAP_USERS,
  GET_USER,
  GET_USERS,
  GET_USERS_WITHOUTANYROLE,
  MAP_USERS,
  REMOVE_MAP_USERS,
} from './actionTypes';
import firebase from 'firebase/app';
import { isUserAdmin } from '../utils/menu';
import { isCharDigit } from '../utils/utils';
import { generateUUID } from '../utils';

const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;

export function getUserObjectFromDb(user) {
  return (dispatch) => {
    dispatch({
      type: GET_USER + STARTED,
    });
    firebase
      .firestore()
      .collection('users')
      //.where('uid', '==', user.uid
      .doc(user.uid)
      .get()
      .then((querySnapshot) => {
        updateUserStore(dispatch, querySnapshot.data());
      });
  };
}

export function updateUserStore(dispatch, userData) {
  dispatch({
    type: GET_USER + FINISHED,
    payload: {
      user: userData,
    },
  });
}

export function updateUsersStore(dispatch, usersData, userRole) {
  dispatch({
    type: GET_USERS + FINISHED,
    payload: {
      users: usersData,
      userRole: userRole,
    },
  });
}

export function updateUsersStoreWithoutAnyRole(dispatch, usersData) {
  dispatch({
    type: GET_USERS_WITHOUTANYROLE + FINISHED,
    payload: {
      users: usersData,
    },
  });
}

export function createOrUpdateUser(user) {
  return (dispatch) => {
    dispatch({
      type: GET_USER + STARTED,
      payload: {
        user: {},
      },
    });
    firebase
      .firestore()
      .collection('users')
      .where('uid', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        let parsedUser = null;
        if (querySnapshot.docs) {
          querySnapshot.docs.forEach((doc) => {
            const data = doc.data();
            if (data) {
              parsedUser = JSON.parse(JSON.stringify(data));
              parsedUser.lastActiveAt = new Date().toUTCString();
              if (parsedUser.createdAt && isCharDigit(parsedUser.createdAt.toString().charAt(0))) {
                parsedUser.createdAt = new Date(
                  parseInt(parsedUser.createdAt)
                ).toUTCString();
              } else {
                parsedUser.createdAt = new Date(parsedUser.createdAt).toUTCString();
              }

              if (parsedUser.lastLoginAt && isCharDigit(parsedUser.lastLoginAt.toString().charAt(0))) {
                parsedUser.lastLoginAt = new Date(
                  parseInt(parsedUser.lastLoginAt)
                ).toUTCString();
              } else {
                parsedUser.lastLoginAt = new Date(
                  parsedUser.lastLoginAt
                ).toUTCString();
              }
              updateUserStore(dispatch, data);
            }
          });
        }
        let userToUpdate = null;
        if (parsedUser) {
          userToUpdate = parsedUser
        } else if (!user.apiKey) {
          userToUpdate = user;
        }

        if (userToUpdate) {
          firebase
            .firestore()
            .collection('users')
            .doc(user.uid)
            .set(JSON.parse(JSON.stringify(userToUpdate)))
            .then(() => {
              console.log('USER CREATED SUCCESSFULLY !!!');
              dispatch({
                type: GET_USER + FINISHED,
                payload: {
                  user: userToUpdate,
                },
              });
            })
            .catch((error) => {
              console.error('ERROR WHILE CREATING USER ::: ', error);
              dispatch({
                type: GET_USER + ERROR,
                payload: {
                  user: null,
                },
              });
            }
            );
        }
      })
      .catch((error) => {
        console.error(
          'error while getting the user with email id in create user :: ',
          error
        );
        logoutUser();
      });
  };
}

function setUserRole(user) {
  const { email } = user;
  if (isUserAdmin(email)) {
    user.role = 'admin';
  } else {
    user.role = 'user';
  }
}

export function logoutUser() {
  return (dispatch) => updateUserStore(dispatch, null);
}

export function getAllUsers(userRole) {
  return (dispatch) => {
    dispatch({
      type: GET_USERS + STARTED,
    });
    firebase
      .firestore()
      .collection('users')
      .where('role', '==', userRole)
      .get()
      .then((querySnapshot) => {
        let users = [];
        querySnapshot.docs.forEach((doc) => {
          if (doc.data()) {
            doc.data.uid = doc.id;
          }
          users.push(doc.data());
        });
        updateUsersStore(dispatch, users, userRole);
      });
  };
}

export function getAllUsersWithoutRoles() {
  return (dispatch) => {
    dispatch({
      type: GET_USERS + STARTED,
    });
    firebase
      .firestore()
      .collection('users')
      .get()
      .then((querySnapshot) => {
        let users = [];
        querySnapshot.docs.forEach((doc) => {
          let temp = {};
          if (doc.data()) {
            temp = doc.data();
            temp.uid = doc.id;
          }
          users.push(temp);
        });
        updateUsersStore(dispatch, users, null);
      });
  };
}

export function updateUserMapping(dispatch, mappedUsers) {
  dispatch({
    type: FETCH_MAP_USERS + FINISHED,
    payload: {
      mappedUsers: mappedUsers,
    },
  });
}

export function fetchMappedStudentsAndTeachers(studentUid) {
  return (dispatch) => {
    dispatch({
      type: FETCH_MAP_USERS + STARTED,
    });
    firebase
      .firestore()
      .collection('association')
      .where('studentUid', '==', studentUid)
      .get()
      .then((querySnapshot) => {
        console.log('Mapped users fetched successfully !!! ', querySnapshot.docs.length);
        let mappedUser = {};
        querySnapshot.docs.forEach((doc) => {
          if (doc.data()) {
            mappedUser = doc.data();
            mappedUser.uid = doc.id;
            updateUserMapping(dispatch, mappedUser);
          } else {
            updateUserMapping(dispatch, mappedUser);
          }
        });
      })
      .catch((error) =>
        console.error('ERROR WHILE fetching mapped USER ::: ', error)
      );
  };
}

export function assignStudentToATeacher(mappingObj) {
  let uid = '';
  if (mappingObj.uid) {
    uid = mappingObj.uid;
  } else {
    uid = generateUUID();
    mappingObj.uid = uid
  }
  return (dispatch) => {
    dispatch({
      type: MAP_USERS + STARTED,
    });
    firebase
      .firestore()
      .collection('association')
      .doc(uid)
      .set(mappingObj)
      .then(() => {
        console.log('USER Mapped SUCCESSFULLY !!!');
        dispatch({
          type: MAP_USERS + FINISHED,
        });
        dispatch({
          type: FETCH_MAP_USERS + FINISHED,
          payload: {
            mappedUsers: mappingObj,
          },
        });
      })
      .catch((error) => console.error('ERROR WHILE Mapping USER ::: ', error));
  };
}

export function removeAssignedTutor(item) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_MAP_USERS + STARTED,
    });
    firebase
      .firestore()
      .collection('association')
      .doc(item.uid)
      .delete()
      .then(() => {
        console.log('USER Mapped Removed !!!');
        dispatch({
          type: REMOVE_MAP_USERS + FINISHED,
        });
      })
      .catch((error) =>
        console.error('ERROR WHILE removing user mapping ::: ', error)
      );
  };
}
