import { ROLES } from "./Constants";

const ADMIN_MENU = [
  {
    name: "Home",
    link: "/home",
  },
  {
    name: "Courses",
    link: "/courses",
  },
  {
    name: "Students",
    link: "/students",
  },
  {
    name: "Tutors",
    link: "/tutors",
  },
  /* {
    name: 'Library',
    link: '/',
  }, */
  {
    name: "Feedbacks",
    link: "/feedbacks",
  },
];

const TUTOR_MENU = [
  {
    name: "Home",
    link: "/home",
  },
  {
    name: "Courses",
    link: "/courses",
  },
  /* {
    name: 'My Account',
    link: '/account',
  },
  {
    name: 'Library',
    link: '/library',
  }, */
  {
    name: "About",
    link: "/about",
  },
  {
    name: "Contact",
    link: "/contact",
  },
];

const STUDENT_MENU = [
  {
    name: "Home",
    link: "/home",
  },
  {
    name: "Courses",
    link: "/courses",
  },
  /* {
    name: 'My Account',
    link: '/acount',
  },
  {
    name: 'Library',
    link: '/library',
  }, */
  {
    name: "About",
    link: "/about",
  },
  {
    name: "Contact",
    link: "/contact",
  },
];

const GUEST_MENU = [
  {
    name: "Home",
    link: "/home",
  },
  {
    name: "Courses",
    link: "/courses",
  },
  {
    name: "About",
    link: "/about",
  },
  {
    name: "Contact",
    link: "/contact",
  },
];

const ADMIN_EMAILS = [
  "pandey.dev25@gmail.com",
  "tiwarismahesh@gmail.com",
  "rhlsingh432@gmail.com",
  "vijay.g.gehlot@gmail.com",
];

export const getMenu = (role) => {
  if (role === ROLES.ADMIN) {
    return ADMIN_MENU;
  } else if (role === ROLES.TUTOR) {
    return TUTOR_MENU;
  } else if (role === ROLES.STUDENT) {
    return STUDENT_MENU;
  } else if (role === ROLES.GUEST) {
    return GUEST_MENU;
  }
};

export const isUserAdmin = (email) => {
  return ADMIN_EMAILS.includes(email);
};
