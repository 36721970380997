import React, { useState } from 'react';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useLocation, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';

import { getMenu } from '../utils/menu';
import logo from '../assets/logo.png';

import '../styles/header.scss';
import { ROLES } from '../utils/Constants';

const TopHeader = ({ history }) => {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const activeMenuName = location.pathname;

  const { user, isUserLoading } = useSelector((state) => state.user);

  const toggleDrawer = (event, open) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const logoClick = (event) => {
    history.push('/');
    toggleDrawer(event, false);
  };

  const list = () => {
    let userRoleMenu;
    if (user) {
      userRoleMenu = getMenu(user.role);
    } else {
      userRoleMenu = getMenu(ROLES.GUEST);
    }
    return (
      <div role="presentation" className="menu-container">
        <div className="logo">
          <p className="navbar-brand">
            <img src={logo} alt="Integral Shiksha" onClick={(event) => logoClick(event)} />
            {/* <span
              style={{ fontWeight: 'bold' }}
              onClick={(event) => logoClick(event)}
            >
              Integral Shiksha Logo
            </span> */}
            <CloseIcon
              className="close-icon"
              onClick={(event) => toggleDrawer(event, false)}
            />
          </p>
        </div>
        <List>
          {userRoleMenu &&
            userRoleMenu.map((userMenu, index) => (
              <ListItem button key={index}>
                <NavLink
                  key={userMenu.link}
                  className={`menu ${activeMenuName === `${userMenu.link}` ? 'active-menu' : ''
                    }`}
                  to={userMenu.link}
                  onClick={(event) => toggleDrawer(event, false)}
                  exact={true}
                >
                  {userMenu.name}
                </NavLink>
              </ListItem>
            ))}
        </List>
        <Divider />
      </div>
    );
  };

  return (
    <div className="top-header-container">
      <div className="contact-container">
        <div className="call-us-container">
          <span className="call-us-contact">Call Us:</span>
          <strong type="tel">
            <a href="tel://+919969581083">(+91) 9969581083</a>
          </strong>
        </div>

        <div className="social-media-container">
          <a
            href="https://www.facebook.com/Integral-Shiksha-113029221028540"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FacebookIcon className="icon" />
          </a>
          <a
            href="https://www.instagram.com/integralshiksha/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <InstagramIcon className="icon" />
          </a>
        </div>
      </div>

      <Drawer
        open={drawerOpen}
        className="menu-drawer"
        onClose={(event) => toggleDrawer(event, false)}
      >
        {list()}
      </Drawer>

      <div className='drawer-container'>
      <div className="logo">
          <img
            src={logo}
            alt="Integral Shiksha"
            onClick={(event) => logoClick(event)}
            className="logo"
          />
          </div>
        <MenuIcon
          className="collapsible-menu-icon"
          onClick={(e) => toggleDrawer(e, true)}
        />
      </div>

    </div>
  );
};

export default withRouter(TopHeader);
