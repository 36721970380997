import React from "react";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import ArrowIcon from "@material-ui/icons/ArrowRight";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import HeartIcon from "@material-ui/icons/Favorite";

import logo from "../assets/logo.png";
import "../styles/footer.scss";

const Footer = ({ history }) => {
  return (
    <div className="main-footer-area">
      <Grid
        container
        justify="center"
        spacing={5}
        className="footer-grid-container"
      >
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8} className="no-gutters">
          <div className="about">
            <h3 className="widget-title">About</h3>
            <span>
              <strong>Integral Shiksha</strong> is a revolutionary learning
              platform for quality online and offline education from the most
              experienced faculty.
              We provide online/offline coaching for all kinds of competitive exams such
              as IIT-JEE, NEET, SAT, ACT, PSAT, Subjective SAT, AP & Olympiads,
              and Foundation Courses for students of Class 9 to 10 (CBSE, ICSE &
              State Syllabus).
            </span>

            <span>
              Integral Shiksha’s faculty has 10+ years of experience with a
              proven track record in teaching English, Maths, Physics,
              Chemistry, and so on. Integral Shiksha is
              driven to provide the best online and on-campus programs to
              students across India.
            </span>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2} xl={2} className="no-gutters">
          <div className="contact-info">
            <h4>(+91) 9969581083</h4>
            <span>info@integralshiksha.com</span>
            <span>
              Palladium A-1407, S Pratap Singh Rd, Sarvodaya Nagar, Bhandup West, Mumbai, Maharashtra 400078
            </span>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2} xl={2} className="no-gutters">
          <div className="useful-links">
            <h5 className="widget-title">Useful Links</h5>
            <ul>
              <li>
                <ArrowIcon className="icon" />
                <NavLink key={"home"} className={"menu"} to={"/"} exact={true}>
                  Home
                </NavLink>
              </li>

              <li>
                <ArrowIcon className="icon" />
                <NavLink
                  key={"about"}
                  className={"menu"}
                  to={"/about"}
                  exact={true}
                >
                  About
                </NavLink>
              </li>

              <li>
                <ArrowIcon className="icon" />
                <NavLink
                  key={"contact"}
                  className={"menu"}
                  to={"/contact"}
                  exact={true}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="no-gutters"
        >
          <div className="copywrite-content">
            <p>
              Copyright ©
              <script
                type="text/javascript"
                async=""
                src="https://www.google-analytics.com/analytics.js"
              ></script>
              <script type="text/javascript">
                document.write(new Date().getFullYear());
              </script>
              2021 All rights reserved to Integral Shiksha | This template is
              made with <HeartIcon className="heart-icon" /> by{" "}
              <a
                href="https://www.devarena.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                DevArena Solutions
              </a>
            </p>
            <div className="social-info">
              <a
                href="https://www.facebook.com/Integral-Shiksha-113029221028540"
                rel="noopener noreferrer"
                target="_blank"
              >
                {" "}
                <FacebookIcon id="social-media-icon" />
              </a>
              <a
                href="https://www.instagram.com/integralshiksha/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {" "}
                <InstagramIcon id="social-media-icon" />
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Footer;
