import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { Route, Router, Switch } from "react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import Firebase, { FirebaseContext } from "./utils/Firebase";
import { CircleLoading } from "react-loadingg";
import Backdrop from "@material-ui/core/Backdrop";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";

import "./styles/layout.scss";
import "./index.css";
import rootReducer from "./reducers/rootReducer";
import Footer from "./components/Footer";

const Signup = lazy(() => import("./screens/Signup"));
const Login = lazy(() => import("./screens/Login"));
const Contact = lazy(() => import("./screens/Contact"));
const About = lazy(() => import("./screens/About"));
const Home = lazy(() => import("./screens/Home"));
const Students = lazy(() => import("./screens/Students"));
const Tutors = lazy(() => import("./screens/Tutors"));
const Feedbacks = lazy(() => import("./screens/Feedbacks"));
const Courses = lazy(() => import("./screens/Courses"));
const CourseDetail = lazy(() => import("./screens/CourseDetail"));
const OfferedCourses = lazy(() => import("./screens/OfferedCourses"));

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

export const muiTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      light: "#ecc13d",
      main: "#37a000",
      dark: "#008329",
    },
    secondary: {
      light: "#ec4712",
      main: "#ec4712",
      dark: "#ec4712",
    },
  },
  appBar: {
    color: "#37517E",
    height: 50,
  },
});

const customHistory = createBrowserHistory();

const Root = () => {
  const classes = useStyles();

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <Suspense
          fallback={
            <Backdrop open={true} className={classes.backdrop}>
              <CircleLoading color="#ef7f1b" />
            </Backdrop>
          }
        >
          <Router history={customHistory}>
            <ScrollToTop />
            <div className="layout-container">
              <Header />
              <div className="content-wrap">
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/signup" component={Signup} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/home" component={Home} />
                  <Route exact path="/courses" component={Courses} />
                  <Route
                    exact
                    path="/offeredCourses"
                    component={OfferedCourses}
                  />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/about" component={About} />
                  <Route exact path="/students" component={Students} />
                  <Route exact path="/tutors" component={Tutors} />
                  <Route exact path="/feedbacks" component={Feedbacks} />
                  <Route
                    exact
                    path="/course/:courseId"
                    component={CourseDetail}
                  />
                </Switch>
              </div>
              <Footer />
            </div>
          </Router>
        </Suspense>
      </MuiThemeProvider>
    </Provider>
  );
};

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <Root />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

registerServiceWorker();
