export const EXECUTION_STATUS = {
    STARTED: '_STARTED',
    FINISHED: '_FINISHED',
    ERROR: '_ERROR',
};

/*USERS ACTION STARTS*/
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USERS = 'UPDATE_USERS';
export const GET_USERS_WITHOUTANYROLE = 'GET_USERS_WITHOUTANYROLE';
/*USERS ACTION ENDS*/


/*FEEDBACK ACTION STARTS*/
export const CREATE_FEEDBACK = 'CREATE_FEEDBACK';
export const GET_APPROVED_FEEDBACK = 'GET_APPROVED_FEEDBACK';
export const GET_ALL_FEEDBACK = 'GET_ALL_FEEDBACK';
export const CLEAR_FEEDBACK_STATE = 'CLEAR_FEEDBACK_STATE';
/*FEEDBACK ACTION ENDS*/


/*USERS MAPPING ACTIONS START*/
export const MAP_USERS = 'MAP_USERS';
export const FETCH_MAP_USERS = 'FETCH_MAP_USERS';
export const REMOVE_MAP_USERS = 'REMOVE_MAP_USERS';
/*USERS MAPPING ACTIONS END*/
