import React from 'react';

const FirebaseContext = React.createContext(null);

export const withFirebase = Component => props => (
  <FirebaseContext.Consumer>
    {(firebase, db) => <Component {...props} firebase={firebase} db={db}/>}
  </FirebaseContext.Consumer>
);

export default FirebaseContext;
