import studentGirl from '../assets/student-girl.png';
import teacher2 from '../assets/teacher2.jpg';

export const TOPSCORER_HSC = [
  {
    name: 'Chaudhury Stuti',
    image: 'https://firebasestorage.googleapis.com/v0/b/integral-shiksha.appspot.com/o/students%2Fstuti.jpeg?alt=media&token=fead0638-3900-447d-9963-43520a77a2c4',
    marks: '538/600',
    year: '',
    details: [{
      name: 'English',
      value: '93',
    }, {
      name: 'Mathematics',
      value: '88',
    }, {
      name: 'Physics',
      value: '92',
    }, {
      name: 'Chemistry',
      value: '93',
    }, {
      name: 'Electronics',
      value: '172',
    },]
  },
  {
    name: 'Ghadshi Sweta',
    image: 'https://firebasestorage.googleapis.com/v0/b/integral-shiksha.appspot.com/o/students%2Fsweta.jpeg?alt=media&token=f20e4e22-d724-4181-a4e0-8db4d42186ae',
    marks: '574/600',
    year: '',
    details: [{
      name: 'English',
      value: '90',
    }, {
      name: 'Marathi',
      value: '94',
    }, {
      name: 'Mathematics',
      value: '98',
    }, {
      name: 'Physics',
      value: '99',
    }, {
      name: 'Chemistry',
      value: '95',
    }, {
      name: 'Biology',
      value: '98',
    },]
  },
  {
    name: 'Khushi',
    image: 'https://firebasestorage.googleapis.com/v0/b/integral-shiksha.appspot.com/o/students%2Ffemale_noimage.jpeg?alt=media&token=50a545d2-9420-4f59-b512-fabe6ba8e165',
    marks: '',
    year: '',
    details: [
      {
        name: 'Matematics',
        value: '99%',
      },
      {
        name: 'Physics',
        value: '89%',
      },
      {
        name: 'Chemistry',
        value: '99%',
      },
    ]
  },
  {
    name: 'Aditya',
    image: 'https://firebasestorage.googleapis.com/v0/b/integral-shiksha.appspot.com/o/students%2Fmale_noimage.png?alt=media&token=14997e3a-2dff-4889-a000-a8a13044df46',
    marks: '94%',
    year: '',
    details: [
      {
        name: 'Matematics',
        value: '94%',
      },]
  },
  {
    name: 'Maitri',
    image: 'https://firebasestorage.googleapis.com/v0/b/integral-shiksha.appspot.com/o/students%2Ffemale_noimage.jpeg?alt=media&token=50a545d2-9420-4f59-b512-fabe6ba8e165',
    marks: '99%',
    year: '',
    details: [
      {
        name: 'Matematics',
        value: '99%',
      },]
  },
  {
    name: 'Himashu Joshi',
    image: 'https://firebasestorage.googleapis.com/v0/b/integral-shiksha.appspot.com/o/students%2Fmale_noimage.png?alt=media&token=14997e3a-2dff-4889-a000-a8a13044df46',
    marks: '94%',
    year: '',
    details: [
      {
        name: 'Matematics',
        value: '94%',
      },]
  }
];

export const TOPSCORER_SSC_X = [
  {
    name: 'Swajeet Salvi',
    image: 'https://firebasestorage.googleapis.com/v0/b/integral-shiksha.appspot.com/o/students%2Fswajeet.jpeg?alt=media&token=46099475-93b3-49a0-b7dd-987d9d6e906d',
    marks: '538/600',
    year: '',
    details: [
      {
        name: 'English',
        value: '83',
      },
      {
        name: 'Hindi',
        value: '86',
      },
      {
        name: 'Mathematics',
        value: '84',
      },
      {
        name: 'Social Science',
        value: '83',
      },
      {
        name: 'Science',
        value: '82',
      },
      {
        name: 'Commercial Application',
        value: '91',
      },
      {
        name: 'SUPW & Community Services',
        value: 'A',
      }]
  },
  {
    name: 'Amit Mishra',
    image: 'https://firebasestorage.googleapis.com/v0/b/integral-shiksha.appspot.com/o/students%2Famit_mishra.jpeg?alt=media&token=8b07a7f7-27ef-4316-affb-b03e6c68ac74',
    marks: '97%',
    year: '',
    details: [
      {
        name: 'Matematics',
        value: '97%',
      },]
  }
];

export const TOPSCORER_ISC = [
  {
    name: 'Aryaman Suri',
    image: 'https://firebasestorage.googleapis.com/v0/b/integral-shiksha.appspot.com/o/students%2Fmale_noimage.png?alt=media&token=14997e3a-2dff-4889-a000-a8a13044df46',
    marks: '94%',
    year: '',
  },
];

export const TOPSCORER_ICSE = [
  {
    name: 'Meet Modi',
    image: 'https://firebasestorage.googleapis.com/v0/b/integral-shiksha.appspot.com/o/students%2Fmale_noimage.png?alt=media&token=14997e3a-2dff-4889-a000-a8a13044df46',
    marks: '98%',
    year: '',
  },
];

export const TOPSCORER_CBSE = [
  {
    name: 'Ankur',
    image: 'https://firebasestorage.googleapis.com/v0/b/integral-shiksha.appspot.com/o/students%2Fmale_noimage.png?alt=media&token=14997e3a-2dff-4889-a000-a8a13044df46',
    marks: '99%',
    year: '',
  },
  {
    name: 'Anuj',
    image: 'https://firebasestorage.googleapis.com/v0/b/integral-shiksha.appspot.com/o/students%2Fmale_noimage.png?alt=media&token=14997e3a-2dff-4889-a000-a8a13044df46',
    marks: '99%',
    year: '',
  },
];

export const testimonyItems = [
  {
    description:
      'I have been working as a tutor with Integral Shiksha \n' +
      'since last 1 years. It is always fun to work with Integral Shiksha ',
    image: teacher2,
    testimonyBy: ' - Rani Mukherjee',
    title: 'Teacher'
  },
  {
    description:
      'I was lucky that I found Integral Shiksha. It helped me clear my boards.',
    image: studentGirl,
    testimonyBy: ' - Preeti Zinta',
  },
];

export const ROLES = {
  ADMIN: 1,
  TUTOR: 2,
  STUDENT: 3,
  GUEST: 4,
};
