import { combineReducers } from 'redux';
import feedbackReducer from './feedbackReducer';
import userReducer from "./userReducer";

const rootReducer = combineReducers({
    user: userReducer,
    feedback: feedbackReducer
});

export default rootReducer;
