import {
  EXECUTION_STATUS,
  FETCH_MAP_USERS,
  GET_USER,
  GET_USERS,
  GET_USERS_WITHOUTANYROLE,
  MAP_USERS,
  REMOVE_MAP_USERS,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  user: null,
  users: [],
  isUserLoading: false,
  isUsersLoading: false,
  isAuthUser: false,
  userType: '',
  isUserMapping: false,
  mappedUsers: [],
};

export default function (state = INITIAL_STATE, action) {
  let { payload } = action;
  const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;

  switch (action.type) {
    case GET_USER + STARTED:
      return {
        ...state,
        isUserLoading: true,
        isAuthUser: false,
      };
    case GET_USER + FINISHED:
      return {
        ...state,
        user: payload.user,
        isUserLoading: false,
        isAuthUser: true,
      };
    case GET_USER + ERROR:
      console.log('IN GET_USER_ERROR :::');
      return {
        ...state,
        isUserLoading: false,
        error: 'Error while getting current user',
        isAuthUser: false,
      };

    case GET_USERS + STARTED:
      return {
        ...state,
        isUserLoading: true,
        isUsersLoading: true,
        isAuthUser: false,
      };
    case GET_USERS + FINISHED:
      localStorage.setItem('users', JSON.stringify(payload));
      return {
        ...state,
        users: payload.users,
        isUserLoading: false,
        isUsersLoading: false,
        isAuthUser: true,
        userRole: payload.userRole,
      };

    case MAP_USERS + STARTED:
      return {
        ...state,
        isUserMapping: true,
      };

    case MAP_USERS + FINISHED:
      return {
        ...state,
        isUserMapping: false,
      };

    case FETCH_MAP_USERS + STARTED:
      return {
        ...state,
        isUserMapping: false,
      };

    case FETCH_MAP_USERS + FINISHED:
      return {
        ...state,
        isUserMapping: false,
        mappedUsers: payload.mappedUsers,
      };

    case REMOVE_MAP_USERS + STARTED:
      return {
        ...state,
        isUserMapping: true,
      };

    case REMOVE_MAP_USERS + FINISHED:
      return {
        ...state,
        isUserMapping: false,
      };

    default:
      return state;
  }
}
