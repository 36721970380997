import React, { useEffect, useState } from 'react';
import { withRouter, useLocation } from 'react-router';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withFirebase } from '../utils/Firebase';
import { NavLink } from 'react-router-dom';

import '../styles/header.scss';
import TopHeader from './TopHeader';
import { getMenu } from '../utils/menu';
import { useDispatch, useSelector } from 'react-redux';
import { createOrUpdateUser, logoutUser } from '../actions/userActions';
import { ROLES } from '../utils/Constants';
import logo from '../assets/logo.png';

const Header = ({ history, firebase }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [scrolled, setScrolled] = useState(false);
  const [isMegaMenuShown, setShowMegaMenu] = useState(false);
  const [megaMenus, setMegaMenuList] = useState('');
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const { user, isUserLoading } = useSelector((state) => state.user);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50 && !scrolled) {
      setScrolled(true);
    } else if (offset < 50 && scrolled) {
      setScrolled(false);
    }
  };

  const activeMenuName = location.pathname;

  window.addEventListener('scroll', handleScroll);

  useEffect(() => {
    if (user === null) {
      handleFirebaseAuth();
    }
  }, [user, isUserLoading]);

  const handleFirebaseAuth = () => {
    if (user !== null) {
      return;
    }
    firebase.firebaseAuth.onAuthStateChanged((user) => {
      if (user && !isUserLoggedIn) {
        makeUserLoggedIn(user);
      } else {
        handleRedirectResult();
      }
    });
  };

  const makeUserLoggedIn = (userFromAuth) => {
    if (userFromAuth) {
      const {
        metadata: { creationTime, lastSignInTime },
      } = userFromAuth;

      if (
        new Date(creationTime).getTime() === new Date(lastSignInTime).getTime()
      ) {
        userFromAuth = JSON.parse(JSON.stringify(userFromAuth));
        userFromAuth.createdAt = creationTime;
      }
      userFromAuth.lastLoginAt = lastSignInTime;

      dispatch(createOrUpdateUser(userFromAuth));
      setIsUserLoggedIn(true);
    }
  };

  const handleRedirectResult = () => {
    firebase.firebaseAuth
      .getRedirectResult()
      .then(function (result) {
        if (result.credential) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const token = result.credential.accessToken;
          // ...
        }
        // The signed-in user info.
        const user = result.user;
        if (user && !isUserLoggedIn) {
          makeUserLoggedIn(user);
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential;
        // ...
      });
  };

  let headerClassName = 'header-top-area';
  if (scrolled) {
    headerClassName += ' fixed-header';
  }

  const handleLogout = () => {
    firebase.logout().then(() => {
      console.log('Logging out');
      setIsUserLoggedIn(false);
      dispatch(logoutUser());
      history.push('/home');
    });
  };

  function LogoutContainer() {
    return (
      <div className="header-button-container">
        <div
          className="login-button button"
          onClick={handleLogout}
        >
          Log out
        </div>
      </div>
    );
  }

  function LoginContainer() {
    return (
      <div className="header-button-container">
        <div
          className="login-button button"
          onClick={() => history.push('/login')}
        >
          Log In
        </div>
        <Button
          variant="contained"
          className="sign-up-button button"
          onClick={() => history.push('/signup')}
        >
          Sign Up
        </Button>
      </div>
    );
  }

  const getLoginLogoutContainers = () => {
    if (isUserLoading) {
      return <CircularProgress size={24} className="btn-progress" />;
    } else if (user != null && isUserLoggedIn && !isUserLoading) {
      return <LogoutContainer />;
    } else {
      return <LoginContainer />;
    }
  };

  const getHeaderMenu = () => {
    let menu = [];
    let userRoleMenu;
    let guestMenu;
    if (user) {
      userRoleMenu = getMenu(user.role);
      userRoleMenu &&
        userRoleMenu.forEach((userMenu) => {
          const { name, link, activeLinks } = userMenu;
          menu.push(
            <NavLink
              key={link}
              className={`menu ${
                activeMenuName === `${link}` ? 'active-menu' : ''
              }`}
              to={link}
              exact={true}
              onMouseEnter={() => setShowMegaMenu(false)}
            >
              {name}
            </NavLink>
          );
        });
    } else {
      guestMenu = getMenu(ROLES.GUEST);
      guestMenu.forEach((userMenu) => {
        const { name, link, activeLinks } = userMenu;
        menu.push(
          <NavLink
            key={link}
            className={`menu ${
              activeMenuName === `${link}` ? 'active-menu' : ''
            }`}
            to={link}
            exact={true}
            onMouseEnter={() => setShowMegaMenu(false)}
          >
            {name}
          </NavLink>
        );
      });
    }

    return menu;
  };

  return (
    <>
      <div className="header">
        <TopHeader />
        <Divider />

        <div className={headerClassName}>
          <div className="logo">
          <img
            src={logo}
            alt="Integral Shiksha"
            onClick={() => history.push('/home')}
            className="logo"
          />
          </div>
          <div className="mega-menu menu">{getHeaderMenu()}</div>
          {getLoginLogoutContainers()}
        </div>
      </div>
    </>
  );
};

export default withRouter(withFirebase(Header));
