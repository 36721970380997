import {
  EXECUTION_STATUS,
  GET_APPROVED_FEEDBACK,
  GET_ALL_FEEDBACK,
  CREATE_FEEDBACK,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  feedbacks: [],
  allFeedbacks: [],
  areFeedbackLoading: false,
  isFeedbackSaving: false,
};

export default function (state = INITIAL_STATE, action) {
  let { payload } = action;
  const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;

  switch (action.type) {
    case CREATE_FEEDBACK + STARTED:
      return {
        ...state,
        isFeedbackSaving: true,
      };
    case CREATE_FEEDBACK + FINISHED:
      return {
        ...state,
        isFeedbackSaving: false,
      };
    case CREATE_FEEDBACK + ERROR:
      console.log('IN CREATE_FEEDBACK_ERROR :::');
      return {
        ...state,
        isFeedbackSaving: false,
        error: 'Error while creating feedback',
      };

    ///////////////////////////////////////////////////////////////////////////////////////////////////

    case GET_APPROVED_FEEDBACK + STARTED:
      return {
        ...state,
        areFeedbackLoading: true,
      };
    case GET_APPROVED_FEEDBACK + FINISHED:
      return {
        ...state,
        feedbacks: payload,
        areFeedbackLoading: false,
      };

    case GET_APPROVED_FEEDBACK + ERROR:
      console.log('IN GET_APPROVED_FEEDBACK_ERROR :::');
      return {
        ...state,
        areFeedbackLoading: false,
        error: 'Error while getting approved feedback',
      };

    ///////////////////////////////////////////////////////////////////////////////////////////////////

    case GET_ALL_FEEDBACK + STARTED:
      return {
        ...state,
        areFeedbackLoading: true,
      };
    case GET_ALL_FEEDBACK + FINISHED:
      return {
        ...state,
        allFeedbacks: payload,
        areFeedbackLoading: false,
      };

    case GET_ALL_FEEDBACK + ERROR:
      console.log('IN GET_APPROVED_FEEDBACK_ERROR :::');
      return {
        ...state,
        areFeedbackLoading: false,
        error: 'Error while getting ALL feedback',
      };

    default:
      return state;
  }
}
