import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

/*const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};*/

var config ={
  apiKey: "AIzaSyBRHoNWtbudBxeltZ88inISkDmPDPV6Btw",
  authDomain: "integral-shiksha.firebaseapp.com",
  projectId: "integral-shiksha",
  storageBucket: "integral-shiksha.appspot.com",
  messagingSenderId: "116183748639",
  appId: "1:116183748639:web:5ce93a647dc07b4781052a",
  measurementId: "G-F455WWY2NC",
  databaseURL: ""
}

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.firebaseAuth = app.auth();
    this.firebaseAuth.setPersistence(app.auth.Auth.Persistence.LOCAL);
    this.db = app.firestore();
  }

  logout = () => {
    return this.firebaseAuth.signOut();
  };

}

export default Firebase;
